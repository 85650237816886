<template>
  <div class="mail">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="lang.base_url">{{ lang.home }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ lang.mail_breadcrumb }}</li>
      </ol>
    </nav>
    <h4>{{ lang.mail_title }}</h4>
    <p v-html="lang.mail_desc"></p>
    <div class="row mb-3">
      <div class="col-md">
        <label for="name" class="form-label"><i class="us-red">*</i> {{ lang.name }}:</label>
        <input type="text" class="form-control" id="name" v-model="name">
      </div>
      <div class="col-md">
        <label for="lastname" class="form-label"><i class="us-red">*</i> {{ lang.lastname }}:</label>
        <input type="text" class="form-control" id="lastname" v-model="lastname">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md">
        <label for="index" class="form-label"><i class="us-red">*</i> {{ lang.index }}:</label>
        <input type="text" class="form-control" id="index" aria-describedby="index-help" v-model="index">
        <div id="index-help" class="form-text">{{ lang.index_hint }}</div>
      </div>
      <div class="col-md">
        <label for="jmbg" class="form-label"><i class="us-red">*</i> {{ lang.jmbg }}:</label>
        <input type="text" class="form-control" id="jmbg" v-model="jmbg">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md">
        <label for="private" class="form-label"><i class="us-red">*</i> {{ lang.private_mail }}:</label>
        <input type="text" class="form-control" id="private" aria-describedby="private-help"
               v-model="privateMail">
        <div id="private-help" class="form-text">{{ lang.private_mail_hint }}</div>
      </div>
      <div class="col-md">
        <label for="singimail" class="form-label">{{ lang.student_mail }}:</label>
        <input type="text" class="form-control" id="singimail" :placeholder="lang.student_mail_example"
               v-model="studentMail">
      </div>
    </div>
    <div class="mb-3">
      <label for="message" class="form-label">{{ lang.message }}:</label>
      <textarea type="text" class="form-control" id="message" v-model="message"></textarea>
    </div>
    <div class="mb-3 form-check">
      <input type="checkbox" class="form-check-input" id="check" v-model="agree">
      <label class="form-check-label" for="check" v-html="lang.agree"></label>
    </div>
    <button type="button" class="btn btn-primary" @click="sendForm">{{ lang.send }}</button>
    <Footer :lang="lang"/>
  </div>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import {MainService} from "@/service/main.service";
import {ref} from "vue";
import {AnimationHelper} from "@/utils/animation.helper";
import Footer from "@/components/Footer.vue";
import {TranslationModel} from "@/models/translation.model";

export interface MailProps {
  lang: TranslationModel
}

const {lang} = defineProps<MailProps>()

const name = ref()
const lastname = ref()
const index = ref()
const jmbg = ref()
const privateMail = ref()
const studentMail = ref()
const message = ref()
const agree = ref()

//@ts-ignore
const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()

async function sendForm() {
  const loading = AnimationHelper.showLoading(lang.processing)
  loading.showLoading();

  await recaptchaLoaded()
  const token = await executeRecaptcha('submit')

  if (name.value == undefined || name.value == '') {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.name_err)
    return
  }

  if (lastname.value == undefined || lastname.value == '') {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.lastname_err)
    return
  }

  if (index.value == undefined || index.value == '') {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.index_err)
    return
  }

  if (jmbg.value == undefined || jmbg.value == '') {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.jmbg_err)
    return
  }

  if (privateMail.value == undefined || privateMail.value == '') {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.priv_email_err)
    return
  }

  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!privateMail.value.match(regex)) {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.priv_email_malformed)
    return
  }

  if (studentMail.value != undefined && !validateSingiMail(studentMail.value)) {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.stud_email_malformed)
    return
  }

  if (agree.value == undefined || agree.value == false) {
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.agree_err)
    return
  }

  MainService.submitSingimail({
    name: name.value,
    lastname: lastname.value,
    index: index.value,
    jmbg: jmbg.value,
    private: privateMail.value,
    student: studentMail.value,
    message: message.value,
    token: token,
    lang: lang.lang_name
  }).then(rsp => {
    loading.close()
    AnimationHelper.alert(lang.success_title, lang.success_msg)
  }).catch(e => {
    console.log(e)
    loading.close()
    AnimationHelper.alertError(lang.error_title, lang.error_msg)
  })
}

function validateSingiMail(email: string) {
  return /^\"?[\w-_\.]*\"?@singimail\.rs$/.test(email);
}
</script>