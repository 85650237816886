<template>
  <footer>
    <p class="text-center">&copy; {{ year }} {{ lang.footer }}</p>
  </footer>
</template>

<script setup lang="ts">
import {TranslationModel} from "@/models/translation.model";

export interface FooterProps {
  lang: TranslationModel
}

const {lang} = defineProps<FooterProps>()

const year = new Date().getFullYear()
</script>