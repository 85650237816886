<template>
  <div class="container">
    <div class="d-flex gap-1 flex-row-reverse">
      <router-link class="btn fi fi-rs lang" to="/home"></router-link>
      <router-link class="btn fi fi-gb lang" to="/en/home"></router-link>
    </div>
    <div class="mb-5">
      <img id="us-logo" src="@/assets/img/logo.png" alt="singidunum" class="rounded mx-auto d-block" @click="goHome">
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";

const router = useRouter()

function goHome() {
  router.push({
    path: '/home'
  })
}

</script>