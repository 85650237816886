<template>
  <div class="text-center mx-auto">
    <img src="@/assets/img/loading.gif" alt="loading">
    <h5 class="fw-bold">{{ message }}</h5>
  </div>
</template>

<script setup lang="ts">
export interface LoadingProps {
  message: string
}

const {message} = defineProps<LoadingProps>()
</script>