import {TranslationModel} from "@/models/translation.model";

export const en: TranslationModel = {
    loading: 'Please wait',
    lang_name: 'en',
    footer: 'Singidunum University',
    home: 'Home',
    question_title: 'Frequently asked questions',
    question_breadcrumb: 'Frequently asked questions',
    base_url: '/en',
    mail_title: 'Singimail Reset Form',
    mail_desc: `Student email address is the only valid way of communication on the Singidunum University`,
    mail_breadcrumb: 'Singimail Reset Form',
    name: 'First name',
    lastname: 'Last name',
    index: 'Index number',
    index_hint: 'Index number has to be in the following format: 2023123456 (where 2023 is the enrolment year)',
    jmbg: "UMCN / Passport number",
    private_mail: 'Private email address',
    private_mail_hint: 'We will send you your new credentials here',
    student_mail: 'SingiMail address',
    student_mail_example: 'name.surname.23@singimail.rs',
    message: 'Message',
    agree: `I confirm that I have entered <span class="fw-bold">my personal data</span> and not from another student`,
    send: 'Send request',
    name_err: 'First name is required',
    lastname_err: 'Last name is required',
    index_err: 'Index number is required',
    jmbg_err: 'UMCN or Passport number is required',
    priv_email_err: 'Private email address is required',
    stud_email_err: 'Student mail address is required',
    priv_email_malformed: 'Private email address is malformed',
    stud_email_malformed: 'Singimail address is malformed',
    agree_err: 'You have to confirm that you have entered your own data',
    success_msg: 'Success! Please be patient while we process your request',
    error_msg: 'Something went wrong, please try again later',
    success_title: 'Success!',
    error_title: 'Error!',
    processing: 'Processing...',
    teams_breadcrumb: 'MS Teams Support',
    teams_title: 'Microsoft Teams Support Form',
    teams_desc: "Are you missing a subject or you have a general question? Feel free to reach out via this form. After submission please be patient",
}