import axios from "axios";
import {CategoryModel} from "@/models/category.model";

const client = axios.create({
    baseURL: 'https://hub.singidunum.ac.rs/api',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-token': '55a871c7-6a5f-4076-a7b2-a9f8e91ecffd'
    }
});

export class MainService {
    public static async retrieveCategories(lang = 'sr') {
        return await client.get<CategoryModel[]>('/category?lang=' + lang)
    }

    public static async submitSingimail(data: any) {
        return await client.request({
            method: 'post',
            url: '/singimail/new',
            data: data
        })
    }

    public static async submitTeams(data: any) {
        return await client.request({
            method: 'post',
            url: '/teams/new',
            data: data
        })
    }

    public static async itemInteraction(item: number) {
        return await client.request({
            method: 'post',
            url: '/interaction/item/' + item
        })
    }
}