import {TranslationModel} from "@/models/translation.model";

export const sr: TranslationModel = {
    loading: 'Molimo sačekajte',
    lang_name: 'sr',
    footer: 'Unverzitet Singidunum',
    home: 'Početna',
    question_title: 'Često postavljana pitanja',
    question_breadcrumb: 'Često postavljana pitanja',
    base_url: '/',
    mail_title: 'Formular za izmenu lozinke studentskog mejla',
    mail_desc: `Studentska mejl adresa je jedini zvaničan način komunikacije na Univerzitetu Singidunum<br>
      Nalozi se dobiju po upisu i prate šemu ime.prezime.godinaupisa@singimail.rs gde godina upisa pretstavlja poslednje
      dve cifre
      godine upisa (npr. pera.peric.23@singimail.rs)<br>
      Inicijalna lozinka svake mejl adrese jeste matični broj ili broj pasoša ukoliko je student strani državljanin<br>
      Ukoliko ste zaboravili lozinku popunite formular ispod.`,
    mail_breadcrumb: 'Izmena lozinke Singimail',
    name: 'Ime',
    lastname: 'Prezime',
    index: 'Broj Indeksa',
    jmbg: "JMBG / Broj Pasoša",
    private_mail: 'Privatna Email adresa',
    student_mail: 'SingiMail adresa',
    student_mail_example: 'ime.prezime.23@singimail.rs',
    message: 'Poruka',
    agree: `Potvrđujem da sam uneo <span class="fw-bold">moje lične podatke</span> a ne podatke drugog studenta`,
    send: 'Pošalji zahtev',
    name_err: 'Morate uneti Vaše ime',
    lastname_err: 'Morate uneti Vaše prezime',
    index_err: 'Morate uneti Vaš broj indeksa',
    jmbg_err: 'Morate uneti Vaš matični broj, odnosno broj pasoša',
    priv_email_err: 'Morate uneti Vašu privatnu mejl adresu kako bi dobili nove parametre',
    stud_email_err: 'Morate uneti Vašu singimejl studentsku adresu',
    priv_email_malformed: 'Privatna adresa je u pogrešnom formatu',
    stud_email_malformed: 'Singimail adresa je u pogrešnom formatu',
    agree_err: 'Morate potvrditi da ste uneli vaše lične podatke',
    success_msg: 'Vaš zahtev je uspešno poslat. Molimo Vas da budete strpljivi dok se vaš zahtev ne obradi',
    error_msg: 'Na žalost došlo je do greške. Molimo Vas da pokušate ponovo kasnije',
    private_mail_hint: 'Na ovu mejl adresu će te dobiti novu parametre vaše mejl adrese',
    index_hint: 'Indeks mora biti u formatu 2019123456 (gde 2019 predstavlja godinu upisa)',
    success_title: 'Uspešno izvršeno!',
    error_title: 'Greška!',
    processing: 'Zahtev se izvršava...',
    teams_breadcrumb: 'MS Teams Podrška',
    teams_title: 'Microsoft Teams Formular za Podršku',
    teams_desc: "Da li ti nedostje predmet ili imaš neko opšte pitanje? Popuni formular kako bi došao do odgovora.<br> Upustvo za prustup predavanjima je dostupno u video formatu: <a href='https://www.youtube.com/watch?v=kTW4xCtiCjI&ab_channel=UniverzitetSingidunum'>Pristup online predavanjima putem aplikacije Microsoft Teams</a>",
}