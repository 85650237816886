import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/assets/css/style.css'
import 'flag-icons/css/flag-icons.css'
import {VueReCaptcha} from "vue-recaptcha-v3";

const site = '6LdM3hYpAAAAAO4gbhR0y3ooKVuk6WSuBAUO-ol2'

// @ts-ignore
createApp(App)
    .use(VueReCaptcha, {siteKey: site})
    .use(router)
    .mount('#app')
