<template>
  <div class="question">
    <div v-if="questions">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="lang.base_url">{{ lang.home }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ lang.question_breadcrumb }}</li>
        </ol>
      </nav>
      <h4 class="mb-3">{{ lang.question_breadcrumb }}</h4>
      <div class="input-group mx-auto mb-3">
        <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
        <input type="text" class="form-control" v-model="search" @keyup="retrieveData">
      </div>
      <div class="accordion accordion-flush" id="questions">
        <div v-for="question in questions" class="accordion-item">
          <h2 class="accordion-header" :id="'qh-'+question.questionId">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#qb-'+question.questionId" aria-expanded="false"
                    :aria-controls="'#qb-'+question.questionId">
              <i class="fa-solid fa-graduation-cap icon"></i> {{ question.text }}
            </button>
          </h2>
          <div :id="'qb-'+question.questionId" class="accordion-collapse collapse"
               :aria-labelledby="'qh-'+question.questionId"
               data-bs-parent="#questions">
            <div class="accordion-body" v-html="parseQuestionBody(question)"></div>
          </div>
        </div>
      </div>
      <Footer :lang="lang"/>
    </div>
    <Loading :message="lang.loading" v-else/>
  </div>
</template>

<script setup lang="ts">
import Footer from "@/components/Footer.vue";
import {QuestionModel} from "@/models/question.model";
import {onMounted, ref} from "vue";
import linkifyHtml from "linkify-html";
import Loading from "@/components/Loading.vue";
import {TranslationModel} from "@/models/translation.model";

export interface QuestionProps {
  lang: TranslationModel,
  callback: Function
}

const {lang, callback} = defineProps<QuestionProps>()

const questions = ref<QuestionModel[]>()
const search = ref<string>('')

function retrieveData() {
  callback(search.value).then((rsp: { data: QuestionModel[] | undefined; }) => {
    questions.value = rsp.data
  })
}

onMounted(() => retrieveData())

function parseQuestionBody(question: QuestionModel) {
  return linkifyHtml(question.answer, {
    attributes: {
      target: '_blank'
    }
  });
}
</script>