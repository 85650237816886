<template>
  <div class="home">
    <div v-if="categories">
      <div v-for="category in categories" class="category pb-3">
        <h4 class="pb-3">
          <i :class="category.icon"></i>
          <span>{{ category.title }}</span>
        </h4>
        <div class="row gy-3 mb-5">
          <div class="col-md-3 pointer" v-for="item in category.items" @click="onCardClick(item)">
            <div class="card h-100">
              <div class="card-header fw-bold">{{ item.title }}</div>
              <div class="card-body h-100">
                <p class="card-text">{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer :lang="lang"/>
    </div>
    <Loading :message="lang.loading" v-else/>
  </div>
</template>

<script setup lang="ts">

import Loading from "@/components/Loading.vue";
import Footer from "@/components/Footer.vue";
import {ref} from "vue";
import {TranslationModel} from "@/models/translation.model";
import {useRouter} from "vue-router";
import {MainService} from "@/service/main.service";

export interface HomeProps {
  lang: TranslationModel
}

const {lang} = defineProps<HomeProps>()

const categories = ref()
MainService.retrieveCategories(lang.lang_name).then(rsp =>
    categories.value = rsp.data
)

const router = useRouter()

function onCardClick(item: any) {
  MainService.itemInteraction(item.itemId).then(rsp => {
    if (item.link.startsWith('/')) {
      router.push({
        path: item.link
      })
      return
    }
    window.open(item.link, '_blank')
  })
}
</script>